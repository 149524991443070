<template>
  <div>
    <div class="m-0">
      <template v-if="$options.components['advertisement-loading']">
        <advertisement-loading v-if="isLoading" />
      </template>
    </div>
    <!-- <div>
      <h2>this one</h2>
    </div>-->
    <sections-slider
      :list="$route.params.items"
      :subTitle="$route.params.market"
      :hasBackground="true"
      :hasButton="false"
      :showTitle="true"
      :withoutSlider="true"
      class="suggested-slider"
    >
      <template slot="default" slot-scope="{ listItem }">
        <div class="item-box">
          <items-item
            :itemNoBg="true"
            :product="listItem"
            :incrementDecrementKey="true"
            class="suggester-div w-100"
          ></items-item>
        </div>
      </template>
    </sections-slider>
  </div>
</template>  

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      isLoading: false,
    };
  },
  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
  computed: {
    ...mapState({
      items: (state) => state.healthcareMarket.itemsList,
    }),
  },
  async mounted() {
    this.isLoading = true;
    await this.$store.dispatch(
      "healthcareMarket/fetchHealthCareList",
      this.$route.params.market
    );
    this.isLoading = false;
  },
};
</script>
